<template>
  <b-card>
    <b-row>
      <b-col cols="11">e</b-col>
      <b-col>
        <b-button-group>
          <b-button variant="primary" @click="getFilter(1)">Getir</b-button>
        </b-button-group>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>


export default {
  name: "HomeFilter",

  methods: {
    getFilter(page) {
      this.$store.state.product.search.page = page
      this.$store.state.product.search.name = '%şeker port%'
      this.$store.dispatch('product/getAll');
    }
  }
}
</script>

<style scoped>

</style>
