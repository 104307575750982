<template>
  <b-card>
    <b-row>
      <b-col>
        <product-detail :product="product"/>
      </b-col>
      <b-col cols="8">
        <b-row class="border rounded" style="background-color: azure;padding: 4px">
          <b-col cols="2">Resim</b-col>
          <b-col cols="3">Pazaryeri</b-col>
          <b-col cols="1">Sabit</b-col>
          <b-col>
            <b-row>
              <b-col cols="4">Mağaza</b-col>
              <b-col>Sabit</b-col>
            </b-row>
          </b-col>
        </b-row>
        <product-stores
            v-for="mpProduct in product.hb_products"
            :product="mpProduct"
            :key="mpProduct.id"
            store-name="Hepsiburada"/>
        <product-stores
            v-for="mpProduct in product.ty_products"
            :product="mpProduct"
            :key="mpProduct.id"
            store-name="Trendyol"/>
        <product-stores
            v-for="mpProduct in product.az_products"
            :product="mpProduct"
            :key="mpProduct.id"
            store-name="Amazon"/>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import ProductDetail from "@/views/product/product/ProductDetail.vue";
import ProductStores from "@/views/product/product/ProductStores.vue";

export default {
  name: "Product",
  components: {ProductDetail, ProductStores},
  props: {
    product: {
      type: Object,
      required: true,
    }
  }

}
</script>

<style scoped>

</style>
