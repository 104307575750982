import axios from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    data: () => ({
        show: false,
    }),
    methods: {
        changeStaticPrice(static_price, state) {
            this.show = true;
            let url = '';
            if (state === 'product') url = `/product/${this.product.id}`;
            if (state === 'Hepsiburada') url = `/hepsiburada/product/${this.product.id}`;
            if (state === 'Trendyol') url = `/trendyol/product/${this.product.id}`;
            if (url) {
                axios.put(url, {static_price})
                    .then(res => {
                        this.show = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: '',
                                icon: 'success',
                                text: res.data.message,
                                variant: 'info'
                            }
                        })
                    })
            } else {
                alert('Geçersiz İşlem')
            }
        }
    }
}
