<template>
  <b-row>
    <b-col cols="4">
      <product-image :images="product.images"/>
    </b-col>
    <b-col cols="6">
      <h4>{{ product.name }}</h4>
      <div>
        <b>{{ product.barcode }} ({{ product.id }})</b>
      </div>
      <div v-if="product.brand">
        <b-badge>{{ product.brand.name }}</b-badge>
      </div>
      <hr>
      <div>
        <b-overlay :show="show">
          <b-form-input
              type="number"
              size="sm"
              v-model="product.static_price"
              placeholder="Sabit Fiyat"
              @change="changeStaticPrice($event,'product')"
          />
        </b-overlay>
      </div>
      <div>
        <b-form-input
            type="number"
            size="sm"
            v-model="product.market_price"
            placeholder="PSF Fiyat"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import ProductImage from "@/components/ProductImage.vue";
import editStaticPrice from "@/mixins/editStaticPrice";

export default {
  name: "ProductDetail",
  mixins: [editStaticPrice],
  components: {ProductImage},
  props: {product: {type: Object, required: true}}
}
</script>

<style scoped>

</style>
