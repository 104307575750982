<template>
  <b-row class="mt-1 p-1 border shadow-sm rounded" style="background-color: #f9f4f4">
    <b-col cols="2">
      <product-image :images="product.images" url=""/>
    </b-col>
    <b-col cols="3">
      <h4>{{ storeName }}</h4>
      <div>
        <b>{{ product.product_name }}</b>
      </div>
      <div>
        <b>{{ product.merchant_sku }} ({{ product.id }})</b>
      </div>
    </b-col>
    <b-col cols="1">
      <b-overlay :show="show">
        <b-form-input
            type="number"
            size="sm"
            v-model="product.static_price"
            placeholder="Sabit Fiyat"
            @change="changeStaticPrice($event,storeName)"
        />
      </b-overlay>
    </b-col>
    <b-col>
      <product-store-product
          v-if="product.hb_store_products"
          v-for="storeProduct in product.hb_store_products"
          :product="storeProduct"
          :key="storeProduct.id"
      />
      <product-store-product
          v-if="product.ty_store_products"
          v-for="storeProduct in product.ty_store_products"
          :product="storeProduct"
          :key="storeProduct.id"
      />
      <product-store-product
          v-if="product.az_store_products"
          v-for="storeProduct in product.az_store_products"
          :product="storeProduct"
          :key="storeProduct.id"
      />
    </b-col>
  </b-row>
</template>

<script>
import ProductImage from "@/components/ProductImage.vue";
import ProductStoreProduct from "@/views/product/product/ProductStoreProduct.vue";
import editStaticPrice from "@/mixins/editStaticPrice";

export default {
  name: "ProductStores",
  mixins: [editStaticPrice],
  components: {ProductImage, ProductStoreProduct},
  props: {
    storeName: {type: String, required: true},
    product: {type: Object, required: true},
  },
  computed: {
    bgColor() {
      if (this.storeName === 'Hepsiburada') return 'bg-info';
      else if (this.storeName === 'Trendyol') return 'bg-warning'
      return 'bg-primary'
    }
  },
}
</script>

<style scoped>

</style>
