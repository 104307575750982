<template>
  <div class="imageContainer m-0 p-0" v-if="image">
    <b-img
        class="border border-primary"
        :src="url + image.url"
        width="150"
    />
  </div>
</template>

<script>


export default {
  name: "ProductImage",
  props: {
    images: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      default: 'https://api.kitapfirsatlari.com/'
    }
  },
  computed: {
    image() {
      if (this.images.length > 0) return this.images[0];
      return null;
    }
  }
}
</script>

<style scoped>

</style>
