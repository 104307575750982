<template>
  <div>
    <home-filter/>
    <product v-for="product in _products" :key="product.id" :product="product"/>
  </div>
</template>

<script>
import HomeFilter from "@/views/product/product/HomeFilter.vue";
import Product from "@/views/product/product/Product.vue";
import {mapGetters} from "vuex";

export default {
  name: "Home",
  components: {HomeFilter, Product},
  computed: {
    ...mapGetters('product', ['_products']),
  }
}
</script>

<style scoped>

</style>
