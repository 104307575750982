<template>
  <b-row class="mb-1 border" style="background-color: rgb(239 235 238)">
    <b-col cols="4"><h4>{{ product.store.store_name }}</h4></b-col>

    <b-col>
      <b-form-input type="number" size="sm" v-model="product.static_price"
                    placeholder=""/>
    </b-col>
  </b-row>
</template>

<script>


export default {
  name: "ProductStoreProduct",
  props: {product: {type: Object, required: true}},
}</script>

<style scoped>

</style>
